<template>
        <div style="padding:0 12px 20px">
        <div>
            <h2 style="margin: 12px 0;">隐私政策</h2>
            <p style="margin: 12px 0;">最新更新时间：2024年4月3日</p>
            <div>
                光谱计划
                APP/Web（以下简称"本应用"）尊重并保护所有使用服务用户的个人隐私权。本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
            </div>
            <div>您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。</div>
            <h4>1. 适用范围</h4>
            <div class="Bold">(a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；</div>
            <div class="Bold">(b)
                在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
            </div>
            <div class="Bold">(c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。</div>
            <div>您了解并同意，以下信息不适用本隐私权政策：</div>
            <div>(a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</div>
            <div>(b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</div>
            <div>(c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</div>
            <h4>2. 信息使用</h4>
            <div class="Bold">
                (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
            </div>
            <div class="Bold">(b) 本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。</div>
        </div>
        <h4>3. 信息披露</h4>
        <div class="Bold">在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</div>
        <div class="Bold">(a) 经您事先同意，向第三方披露；</div>
        <div class="Bold">(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</div>
        <div class="Bold">(c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</div>
        <div class="Bold">(d) 如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；</div>
        <div class="Bold">(e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</div>
        <div class="Bold">(f) 在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
        </div>
        <div class="Bold">(g) 其它本应用根据法律、法规或者网站政策认为合适的披露。</div>
        <h4>4. 信息存储和交换</h4>
        <div>
            <span
                class="Bold">本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。我们仅在为提供光谱计划服务之目的所必需期间内保留你的个人信息，</span>例如，我们需要持续保留你的手机号，以便于向你提供正常的服务、应对你可能的查询和客诉，并保障你的账号和系统安全。如果你注销帐户、主动删除个人信息或超出必要的期限后，我们将对你的个人信息进行删除或匿名化处理，但以下情况除外：
        </div>
        <div>1.遵从法律法规有关信息留存的要求（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）；</div>
        <div>2.出于财务、审计、争议解决等目的需要合理延长期限的。</div>
        <h4>5. Cookie的使用</h4>
        <div>(a) 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本应用平台服务或功能。</div>
        <div>(b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
        </div>
        <div>(c) 通过本应用所设cookies所取得的有关信息，将适用本政策。</div>
        <h4>6. 信息安全</h4>
        <div class="Bold">(a)
            本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
        </div>
        <h4>7.APP的第三方及关联方SDK说明</h4>
        <div>
            为保障光谱计划App相关功能的实现与应用安全稳定的运行，我们接入了由第三方及关联方提供的软件开发包（SDK）实现相关目的。我们会对获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。请注意，如第三方及关联方SDK可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
        </div>
        <div>—已接入的SDK信息说明：</div>
        <div>1.微信SDK</div>
        <div>使用目的：微信登录</div>
        <div>收集数据类型：设备识别信息</div>
        <div>官网链接：https://open.weixin.qq.com/</div>
        <div>2.友盟统计分析SDK</div>
        <div class="Bold">使用目的：进行APP运营统计与分析。</div>
        <div>收集个人信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID/SN）、位置信息、网络信息</div>
        <div>隐私权政策链接：https://www.umeng.com/page/policy</div>
        <div>3.极光推送SDK</div>
        <div>使用目的：通过识别设备信息为App赋予推送能力，用于消息推送；地理位置和网络信息用于创建智能标签，实现区域、分群推送功能</div>
        <div class="Bold">收集数据类型：设备信息[ 设备信息包括：IMEI、IDFA、Android ID、GAID、
            MAC、OAID、VAID、AAID、IMSI、MEID、UAID、硬件序列号信息、ICCID、SIM信息。
            其中UAID（Uniform Anonymous
            Identifier）为电信运营商推出的统一匿名标识）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）]、网络信息[
            网络信息包括：IP地址、WiFi信息、基站信息、DNS地址、DHCP地址、SSID、BSSID ]、地理位置信息</div>
        <div>隐私策略链接：https://www.jiguang.cn/license/privacy</div>
        <div>4.BuglySDK</div>
        <div>使用目的：统计崩溃</div>
        <div class="Bold">收集数据类型：设备识别信息、位置信息、网络信息</div>
        <div>隐私策略链接：https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</div>
        <div>5.腾讯TBS SDK</div>
        <div>使用目的：预览jdf/word等文件</div>
        <div class="Bold">收集个人信息：Mac地址和唯一设备识别码（IMSI、AndroidID）。</div>
        <div>隐私策略链接：https://x5.tencent.com/tbs/guide/develop.html#5</div>
        <div>6.微博 SDK</div>
        <div>使用目的：分享</div>
        <div class="Bold">收集个人信息：1、个人信息类型：设备信息（包括设备型号、设备标识符（如IMEI/ AndroidID/ IDFA/ OPENUDID/ GUID/
            OAID）、设备MAC地址、电信运营商等软硬件特征信息）
            目的和用途：用于保障用户账号登录和功能实现过程中的安全风控 2、个人信息类型：用户分享时，获取用户选择的图片或视频 目的和用途：用于实现微博分享功能，将用户选择的图片或视频分享至微博</div>
        <div>隐私策略链接：https://weibo.com/signup/v5/privacy</div>
        <div>7.QQ SDK</div>
        <div>使用目的：分享</div>
        <div class="Bold">收集个人信息：操作系统信息、设备型号信息、应用列表信息、剪切板信息</div>
        <div style="word-break:break-all;">隐私策略链接：https://wiki.connect.qq.com/</div>
        <div>8.微信Open SDK</div>
        <div>使用目的：分享</div>
        <div class="Bold">收集个人信息：操作系统信息、设备型号信息、应用列表信息、剪切板信息</div>
        <div>隐私策略链接：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8#__section1
        </div>
        <div>9.华为推送SDK</div>
        <div>使用目的：1、提供消息推送服务；2、统计华为推送SDK接口调用成功率。</div>
        <div class="Bold">收集数据类型：设备信息应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</div>
        <div>
            隐私策略链接：https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
        </div>
        <div>10.vivo推送SDK</div>
        <div>使用目的：1、提供消息推送服务；2、统计vivo推送SDK接口调用成功率。</div>
        <div class="Bold">收集数据类型：设备信息应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</div>
        <div>隐私策略链接：https://dev.vivo.com.cn/documentCenter/doc/652</div>
        <div>11.oppo推送SDK</div>
        <div>使用目的：1、提供消息推送服务；2、统计oppo推送SDK接口调用成功率。</div>
        <div class="Bold">收集数据类型：设备信息应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</div>
        <div>隐私策略链接：https://open.oppomobile.com/new/developmentDoc/info?id=10288</div>
        <div>12.小米推送SDK</div>
        <div>使用目的：1、提供消息推送服务；2、统计小米推送SDK接口调用成功率。</div>
        <div class="Bold">收集数据类型：设备信息应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</div>
        <div>隐私策略链接：https://dev.mi.com/distribute/doc/details?pId=1534</div>
        <div>13.GSYVideoPlayer SDK</div>
        <div>使用目的：视频播放</div>
        <div class="Bold">收集个人信息：获取传感器信息用于重力旋转与手动旋转的同步支持</div>
        <div>14.七牛 SDK</div>
        <div>使用目的：上传用户手动选择的文件或视频做存储，sdk中包含的其他功能均未使用</div>
        <div class="Bold">收集个人信息：设备品牌、型号、操作系统版本、处理器信息</div>
        <div>隐私策略链接：https://www.qiniu.com/agreements/privacy-right</div>
        <h4>8. 权限用途说明</h4>
        <div><span class="Bold">(a) 访问设备的电话功能权限，</span>此权限可让应用确定本机号码和设备 ID，主要用来确定用户账号与设备id的关系。</div>
        <div><span class="Bold">(b) 访问SD卡权限，</span>此权限让应用可以读写SD卡的内容，主要用来缓存用户账号信息和网络数据的，用来提升用户体验。</div>
        <div><span class="Bold">(c) 相机拍照权限，</span>此权限让应用可以拍照上传图片当做用户头像。</div>
        <div><span class="Bold">(d) 获取软件安装列表权限，</span>极光sdk用于优化推送服务质量。</div>
        <h4>9.自启动及关联启动说明</h4>
        <div>1. 为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的；</div>
        <div>2.
            当您打开光谱计划app内容类推送消息，在征得您的明确同意后，会跳转光谱计划app打开相关内容。在未征得您同意的情况下，则不会有关联启动；(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的；
        </div>
        <div>3. 当您打开光谱计划app内部下载的文件后，会关联启动第三方APP。(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的；</div>
        <h4>10.未成年人个人信息保护规则</h4>
        <div class="Bold">
            我们致力于保护使用我们产品或服务的未成年人（是指不满十四周岁的未成年人，下同）的个人信息保护。依据《个人信息保护法》、《未成年人保护法》、《未成年人个人信息网络保护规定》等法律法规，我们将进一步向监护人和未成年人说明我们收集、使用、存储和共享未成年人用户个人信息的情况。
        </div>
        <h4>-未成年人监护人特别说明：</h4>
        <div>
            如果您是未成年人用户的监护人，请您仔细阅读和选择是否同意本声明。我们希望请您与我们共同保护未成年人的个人信息，教育引导未成年人增强个人信息保护意识和能力，应在您的同意和指导下提交个人信息、使用本服务。如果您不同意本规则的任意内容，您应要求您监护的未成年人立即停止访问、使用光谱计划app。
        </div>
        <h4>-未成年人特别说明：</h4>
        <div>
            如您为未满十四周岁的未成年人，您访问、使用本服务前应当取得监护人的同意，请通知您的监护人，在您监护人的陪同下共同阅读本规则，寻求并取得您的监护人的同意和指导。继续使用本服务、提交个人信息，都表示您已获得您的监护人的许可，且您的监护人亦同意受本规则的约束。
        </div>
        <div>如您在阅读本规则过程中有任何疑问或权利请求等个人信息相关事宜的，我们为您提供了专门的反馈渠道，具体参见本声明“12.当前应用运营者信息”，我们会尽快为您作出解答。</div>
        <h4>-我们如何使用未成年人个人信息</h4>
        <div class="Bold">
            我们严格遵守法律法规的规定以及与用户的约定，按照本规则及《用户协议》、《隐私政策》等所述使用收集的信息，以向您的孩子提供更为优质的服务。若我们使用您孩子的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您孩子的个人信息前，再次向您告知并征得您的同意。
        </div>
        <h4>-我们如何共享、转让、公开披露您的个人信息</h4>
        <div class="Bold">
            我们严格遵守法律法规的规定以及与您的约定，按照本规则及《隐私政策》所述“共享、转让、披露的信息“的目的、方式和范围，履行我们有关共享、转让、披露未成年人个人信息的义务和行使我们的权利。除前述情形外，未经监护人同意，我们不会与任何第三方共享、转让、披露未成年人个人信息。
        </div>
        <div class="Bold">如我们需要委托第三方处理未成年人个人信息的，我们会对受委托方及委托行为等进行安全评估，签署委托协议，明确双方责任、处理事项、处理期限、处理性质和目的。</div>
        <div class="Bold">除非法律、行政法规规定应当披露或者根据与您的约定可以披露您孩子的个人信息的，我们不会披露孩子的个人信息。</div>
        <h4>-我们如何存储及保障未成年人个人信息的安全</h4>
        <div class="Bold">
            我们会按照法律法规的规定，将中华人民共和国境内（以下简称“中国”）收集的用户个人信息存储于中国境内，并依法对这些信息进行严格保密。如涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们也会根据国内法律、行政法规和相关监管部门的规定，为您孩子的个人信息提供保护。
        </div>
        <div class="Bold">
            我们已使用符合业界标准的安全防护措施保护未成年人的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，确保未收集无关的未成年人个人信息。我们承诺将使您的个人信息存储时间始终处于法律要求或实现产品功能和服务所需的合理必要的期限内。对于超出期限的个人信息，我们会立即删除或做匿名化处理。
        </div>
        <div class="Bold">
            互联网并非绝对安全的环境，请监护人和未成年人务必妥善保管好帐号、密码及其他身份要素。电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </div>
        <div class="Bold">
            在不幸发生未成年人个人信息安全事件后，我们将按照法律法规的要求，及时向监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、未成年人及监护人可自主防范和降低风险的建议、对未成年人的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知未成年人及监护人，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </div>
        <h4>-您的权利</h4>
        <div class="Bold">我们将尽量采取技术手段保证监护人及未成年人可以更正、删除未成年人个人信息以及注销未成年人注册账号。</div>
        <div>
            但请注意，若未成年人用户或监护人要求我们删除或撤回授权某些特定未成年人个人信息，可能导致该未成年人用户无法继续使用我们的产品和服务或产品和服务中的某些具体业务功能，但撤回授权的决定，不会影响此前基于监护人和未成年人的授权而开展的信息处理。如监护人发现我们在未事先征得监护人同意的情况下收集了未成年人个人信息，请及时联系我们，我们会及时尽快删除相关数据。
        </div>
        <h4>11.本隐私政策的更改</h4>
        <div>(a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。</div>
        <div>(b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。</div>
        <div class="Bold">
            为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
        </div>
        <h4>12.当前应用运营者信息</h4>
        <div>公司主体：北京贝湾教育科技有限公司</div>
        <div>注册地址：北京市朝阳区工人体育场北路甲2号A栋12层1203单元</div>
        <div>常用办公地址：北京市朝阳区西大望路27号21幢米座中心301</div>
        <div>联系电话：010-53683873</div>
    </div>
</template>

<script >
export default {
  data: function () {
    return {
    }
  },
  methods: {
    init () {}
  },
  created () { this.init() },
  mounted () {},
  beforeDestroy () {}
}
</script>
<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
    font-family: '微软雅黑';
    color: rgb(0, 0, 0)
  }
  h2 {
    font-size: 1.5em;
    font-family: '宋体';
  }
  h4 {
    margin-top: 5.0000pt;
    margin-bottom: 5.0000pt;
    font-size: 13.5000pt;
    font-family: '宋体';
  }
  div {
    margin-top: 5.0000pt;
    margin-bottom: 5.0000pt;
    font-size: 12.0000pt;
    font-family: Calibri;
    word-break: break-all;
  }
  .Bold {
    font-weight: 600;
  }
</style>
